<template>
  <b-modal :modal-class="{'activeTour' : getGuidedTour.step5}" centered modal-class="add-rss-feed"
           id="create-new-rss-feed" hide-footer
           hide-header>
    <div class="w-full h-full">
      <div @click="$bvModal.hide('create-new-rss-feed')" class="float-right w-6 h-6 flex justify-end">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 cursor-pointer" viewBox="0 0 12 12" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8161 2.24461C11.109 1.95172 11.109 1.47684 10.8161 1.18395C10.5232 0.891056 10.0483 0.891056 9.75546 1.18395L6.00007 4.93933L2.24469 1.18395C1.95179 0.891056 1.47692 0.891056 1.18403 1.18395C0.891132 1.47684 0.891132 1.95172 1.18403 2.24461L4.93941 5.99999L1.18403 9.75538C0.891132 10.0483 0.891132 10.5231 1.18403 10.816C1.47692 11.1089 1.95179 11.1089 2.24469 10.816L6.00007 7.06065L9.75546 10.816C10.0483 11.1089 10.5232 11.1089 10.8161 10.816C11.109 10.5231 11.109 10.0483 10.8161 9.75538L7.06073 5.99999L10.8161 2.24461Z" fill="black"/>
        </svg>
      </div>
      <div class="w-full  flex flex-col items-center pt-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <g clip-path="url(#clip0_2584_6831)">
            <path d="M31.0489 31.7542C31.8898 31.2851 31.9897 30.7289 31.9949 30.3521C32.0026 29.3934 32.0128 28.4168 31.9282 27.4863C31.7667 25.7125 31.4719 24.0182 31.0464 22.4546C30.6132 20.8577 30.1081 19.4146 29.5006 18.0381C28.6598 16.1336 27.7344 14.4854 26.6705 13.0038C26.0886 12.1912 25.4221 11.3428 24.6351 10.4098C24.1224 9.79969 23.5046 9.09479 22.833 8.48729C21.7127 7.4748 20.6566 6.58534 19.6055 5.77534C18.2033 4.69364 16.5755 3.73241 14.4786 2.74811C12.8866 1.99963 11.1768 1.38701 9.39774 0.923051C8.29801 0.638527 7.17264 0.443718 6.11904 0.274541C5.4833 0.17201 4.83217 0.136124 4.14516 0.0951114L3.87599 0.0797317C3.55043 0.0592255 3.22487 0.0540989 2.88136 0.0464091C2.50965 0.0387192 2.12513 0.0310294 1.73804 0.00539657C0.886966 -0.0535589 0.105103 0.771818 0.0563967 1.48697C0.0512697 1.56387 0.0358888 1.65359 0 1.75099V1.93042C0.0256349 2.00732 0.0512697 2.08422 0.0769046 2.16112C0.148682 2.38156 0.217896 2.58918 0.302491 2.73785C0.640872 3.31459 1.1305 3.59912 1.84827 3.635C2.13282 3.64782 2.41737 3.65807 2.70191 3.66576C3.32484 3.68627 3.97084 3.70677 4.61684 3.77855C7.55716 4.10921 10.3385 4.86794 12.879 6.03423C14.5247 6.78784 16.1423 7.74394 17.6881 8.87435C19.0159 9.84583 20.3131 11.0275 21.5435 12.3886C22.915 13.9035 24.107 15.5799 25.0863 17.3691C26.0732 19.171 26.7679 20.8295 27.2114 22.4418C27.5113 23.5363 27.7805 24.7154 28.0112 25.9458C28.1445 26.6661 28.206 27.3915 28.2624 28.0913L28.2958 28.4809C28.3265 28.8295 28.3368 29.173 28.3445 29.5036C28.3496 29.7164 28.3573 29.9317 28.3701 30.1445C28.4111 30.8801 28.8238 31.7414 29.8646 31.9439C29.9236 31.9541 29.98 31.9721 30.0287 31.99H30.3671C30.4311 31.9695 30.4952 31.949 30.5593 31.9285C30.7465 31.8695 30.9259 31.8132 31.0489 31.7439V31.7542Z" fill="#2560D7"/>
            <path d="M21.8768 30.5338C21.9127 30.0545 21.9127 29.6033 21.8768 29.1881L21.8332 28.6498C21.764 27.7911 21.6999 26.9811 21.5487 26.189C21.259 24.669 20.7668 23.1644 20.0849 21.7161C19.2415 19.927 18.1726 18.2993 16.9088 16.8792C15.991 15.8488 15.0912 14.9952 14.1556 14.2724C12.8072 13.2291 11.3511 12.3678 9.82841 11.7142C8.42874 11.1118 7.05728 10.694 5.64223 10.43C4.14259 10.1506 2.81984 10.0327 1.59705 10.066C1.10999 10.0788 0.72034 10.289 0.438356 10.6863C0.312745 10.8632 0.205079 11.0964 0.0922855 11.3451C0.0615237 11.4143 0.0307618 11.4809 -0.00256348 11.5501V12.1653C0.010254 12.1987 0.0230714 12.2371 0.0333253 12.2756C0.125611 12.6421 0.297364 12.9497 0.543459 13.1932C0.907474 13.5546 1.40223 13.7341 2.05335 13.7443C3.03773 13.7597 3.9734 13.8494 4.90908 14.0186C6.29592 14.2698 7.65457 14.7107 8.944 15.3336C10.0181 15.8513 11.0563 16.505 12.033 17.2765C12.9892 18.0327 13.8633 18.8914 14.6298 19.8347C15.4963 20.8984 16.232 22.0955 16.8165 23.3925C17.5189 24.9484 17.9726 26.6069 18.17 28.3268C18.2136 28.7113 18.2315 29.0881 18.2469 29.4547C18.2623 29.8084 18.2777 30.1442 18.3161 30.4697C18.3623 30.8619 18.5494 31.1977 18.9108 31.5284C19.1416 31.7411 19.4594 31.8667 19.8234 32H20.3284C20.6873 31.8898 21.0437 31.7488 21.3103 31.5079C21.7794 31.0823 21.8537 30.7491 21.8691 30.5338H21.8768Z" fill="#2560D7"/>
            <path d="M0.0667082 27.627C0.182065 29.7418 1.90216 31.9308 4.58357 31.9308H4.59639C5.83968 31.9564 7.04452 31.4361 7.92379 30.521C8.80051 29.6085 9.24655 28.396 9.1799 27.1093C9.05173 24.7203 7.10604 22.8773 4.65279 22.8209C4.61946 22.8209 4.5887 22.8209 4.55537 22.8209C3.36335 22.8209 2.19953 23.3182 1.34845 24.1974C0.451231 25.1202 -0.00250595 26.3377 0.0667082 27.627Z" fill="#2560D7"/>
          </g>
          <defs>
            <clipPath id="clip0_2584_6831">
              <rect width="32" height="32" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        <p class="text-[#3C4549] font-poppins pt-[1.188rem] text-[0.875rem] font-bold leading-5">{{computeTopHeading}}</p>
        <p class="pt-1 text">{{computeSubHeading}}</p>
        <div class="pt-8 w-full flex items-center">
          <div class=" flex-1">
            <FloatingCampaignSearchDropdownV2
            @click="getRSSAdd.cta_id = $event"
            :ctaId="getRSSAdd.cta_id"
            custom-class="!bg-[#F4F6FA] !h-[3.5rem] !rounded-[0.571rem]"
            >
            </FloatingCampaignSearchDropdownV2>
            <URLInputFloatingV2
              class="pt-3"
              id="url"
              @onEnter="validateAndStoreRSS($event)"
              v-model="getRSSAdd.url"
              :validations="validations"
              v-bind:blur="() => getRSSAdd.url = focusoutConcatenation(getRSSAdd.url)"
              type="text"
              label="RSS Feed URL"
              customClass="!h-[3.5rem] !rounded-[0.571rem] !bg-[#F4F6FA] "
              :errorType="validations.url || validations.urlValid || validations.urlLength  ? 'danger' : ''"
            >
              <template v-slot:error_message>
                <InputFieldMessage v-if="validations.url" :message="messages.url"></InputFieldMessage>
                <InputFieldMessage v-else-if="validations.urlLength" :message="messages.urlLength"></InputFieldMessage>
                <InputFieldMessage v-else-if="validations.urlValid" :message="messages.urlValid"></InputFieldMessage>
              </template>
            </URLInputFloatingV2>
          </div>
        </div>
        <div class="w-full px-3 py-4 bg-[#FFFEF9] !rounded-[0.571rem] border !border-[#FFEDCA] justify-start items-center my-[2.85rem]">
          <div class="flex flex-col">
            <span class=" text-sm !font-semibold text-[#757A8A] font-poppins leading-6">Note:<br/></span>
            <span class=" text-sm font-normal font-poppins text-[#757A8A] leading-normal  ">Your RSS feed content will be automatically pulled after every 30 minutes.</span></div>
        </div>
        <div class="  flex gap-x-3 justify-between items-center">
          <Button
            id="login-button"
            type="button"
            class="border border-zinc-700 !py-[1.142rem] !rounded-[0.571rem] hover:bg-[#F2F3F8]"
            buttonClass="btn-lg"
            @click="$bvModal.hide('create-new-rss-feed')"
          >
            <template v-slot:label>Close</template>
          </Button>
          <Button
            id="login-button"
            type="button"
            class="text-white bg-[#2560D7] !py-[1.142rem] !rounded-[0.571rem] hover:bg-blue-700"
            buttonClass="btn-lg"
            :disabled="getRSSLoaders.store"
            @click.prevent="validateAndStoreRSS()"
          >
            <template v-if="!getRSSLoaders.store" v-slot:label>{{computeButtonTitle}}</template>
            <template v-if="getRSSLoaders.store" v-slot:loader>
              <Loader></Loader>
            </template>
          </Button>
        </div>
      </div>
    </div>
  </b-modal></template>
<script>
import { mapActions, mapGetters } from 'vuex'
import {
  rssFeedMessages, rssFeedValidations
} from '@/common/attributes'
export default {
  data () {
    return {
      messages: rssFeedMessages,
      validations: rssFeedValidations,
      search_query: ''
    }
  },
  computed: {
    ...mapGetters(['getRSSAdd', 'getRSS', 'getRSSLoaders', 'getCampaigns', 'getComponentCampaignList', 'getCampaignLoaders', 'getCampaignsList']),
    computeTopHeading () {
      return this.getRSSAdd._id ? 'Edit RSS Feed' : 'Add RSS Feed'
    },
    computeSubHeading () {
      return this.getRSSAdd._id ? 'Please enter details to edit RSS feed.' : 'Please enter details to add RSS feed.'
    },
    computeButtonTitle () {
      return this.getRSSAdd._id ? 'Update' : 'Add'
    }
  },
  components: {
    URLInputFloatingV2: () => import('@/ui/ui-kit/v2/URLInputFloatingV2.vue'),
    Button: () => import('@/ui/ui-kit/v2/Button.vue'),
    InputFieldMessage: () => import('@/ui/ui-kit/v2/InputFieldMessage.vue'),
    Loader: () => import('@/ui/ui-kit/v2/Loader.vue'),
    FloatingCampaignSearchDropdownV2: () => import('@/components/v2/FloatingCampaignSearchDropdownV2.vue')
  },
  methods: {
    ...mapActions([]),
    async validateAndStoreRSS () {
      const payload = this.getRSSAdd
      this.validations.campaign = this.requiredCheck(payload.cta_id)
      this.validations.url = this.requiredCheck(this.getRSSAdd.url)
      this.getRSSAdd.url = this.urlConcatenation(this.getRSSAdd.url)
      this.validations.urlValid = !this.isValidURL(this.getRSSAdd.url)
      let result = Object.keys(this.validations).every(k => this.validations[k] === false)
      if (result) {
        let res = await this.$store.dispatch('storeRSS')
        if (res.data.status) {
          this.$bvModal.hide('create-new-rss-feed')
        }
      }
    },
    selectCampaignForRss (cta) {
      this.getRSSAdd.cta_id = cta._id
      this.getSelectedCampaign()
    },
    /**
     *  getting the selected campaign name
     * @returns {string|*|string}
     */
    getSelectedCampaign () {
      // if creating new rss so cta_id is null
      if (!this.getRSSAdd.cta_id) {
        return 'Select your Campaign'
      }
      // while editing rss so ge campaign name from list if not then get from rss feed list
      const campaignName = this.getCampaignName(this.getRSSAdd.cta_id)
      if (!campaignName) {
        const campaign = this.getRSS.items.find(item => this.getRSSAdd.cta_id === item.cta_id)
        return campaign && campaign.cta && campaign.cta.name ? campaign.cta.name : 'Select your Campaign'
      }
      // is selected campaign exists then simple returs its name
      return campaignName && campaignName.name ? campaignName.name : 'Select your Campaign'
    }
  },
  watch: {
    'getRSSAdd.cta_id' (value) {
      if (value && value.length > 1) this.validations.campaign = false
    }
  }
}
</script>
<style lang="less">
.add-rss-feed {
  .modal-dialog {
    max-width: 36rem !important;
  }
  .modal-content {
    border-radius: 16px !important;
    border-color: #F2F3F8 !important;
  }
  .modal-body {
    padding: 40px 40px 56px 40px !important;  }
}
</style>
